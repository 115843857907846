import React from 'react';
import styles from './UX.module.scss';
import Hero from '../../components/Hero/Hero';
import Banner from '../../components/Banner/Banner';
import Overview from '../../components/Overview/Overview';
import Detail from '../../components/Detail/Detail';
import { Redirect } from 'react-router-dom';
import UXData from '../../constants/UXData';

const UX = (props) => {
    if (!props.match.params.pageID) {
      return (
        <Redirect to="/ux/1" />
      );
    }
    else {
      console.log(props);
      const pageID = props.match.params.pageID; 
      const pageData = UXData[pageID];

      return(
        <div className={styles.main}> 
          <Hero  
            bgColor={pageData.heroData.bgColor}
            imgPath={pageData.heroData.imgPath} 
            imgAlt={pageData.heroData.imgAlt} 
          />
          <Overview 
            desc={pageData.overviewData.desc}
            client={pageData.overviewData.client}
						clientLink={pageData.overviewData.clientLink}
            role={pageData.overviewData.role}
            roleName={pageData.overviewData.roleName}
          />
          {pageData.detailData.map((ddata, index) => {
            const {
              title, 
              imgLink, 
              imgLinkTitle, 
              imgPath, 
              imgAlt, 
              subTitle, 
              desc, 
              slidesData,
							urllink,
							linkText,
							items
            } = ddata;
            return(
              <Detail 
                key={`${title}_${index}`}
                title={title}
                imgLink={imgLink}
                imgLinkTitle={imgLinkTitle}
                imgPath={imgPath}
                imgAlt={imgAlt}
                subTitle={subTitle}
                desc={desc}
                slidesData={slidesData}
								urllink={urllink}
								linkText={linkText}
              />
            )
            })
          }
          <Banner 
            imgPath={pageData.bannerData.imgPath}
            imgAlt={pageData.bannerData.imgAlt} 
          />
        </div>
      )
    }
}

export default UX;