import React from 'react';
import styles from './Home.module.scss';
import Hero from '../../components/Hero/Hero';
import Hero1 from "../../assets/images/HeroHome.jpg";
import ThumbLayout from '../../components/ThumbLayout/ThumbLayout'; 
import ThumbnailsData from '../../constants/ThumbnailsData';

const Home = (props) =>{
  console.log(props);
  
  return(
    <div>
			{/* <div className={styles.heroHome}>
        Kazue Otsuki
      </div> */}
      <Hero 
        bgColor="#CED4D4"
        imgPath={Hero1} 
        imgAlt="Kazue Otsuki"
      />
      <ThumbLayout thumbnails={ThumbnailsData} />
    </div>
  )
}

export default Home;
