import React from 'react';
import Styles from '../ThumbLayout/ThumbLayout.module.scss';
import './Thumbnail.scss';
import { NavLink } from 'react-router-dom';

const Thumbnail = (props) => {
  return (
    <div className={(Styles.thumbnail, Styles.NavLink)}>
       <NavLink to={props.goTo} className={Styles.thumbLinkImg}>
        <img 
            className={Styles.thumbnailImage}
            src={props.imgPath} 
            alt={props.imgAlt}
          />  
      </NavLink>
      <NavLink to={props.goTo} className={Styles.thumbLinkImg}>
        <div className={Styles.thumbBox}>
          {props.title &&
            <h4>{props.title}</h4>
          }
          {!props.title &&
              <h4>{props.title}</h4>
          }
          <p>{props.desc}</p>
        </div>
      </NavLink>
    </div>);
}

export default Thumbnail;
