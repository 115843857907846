import React from 'react';
import { Link } from 'react-router-dom';
import "./Overview.scss";

const Overview = (props) =>{
  const {
    desc, 
    client, 
		clientLink, 
    role, 
    // roleName,
		teamName,
  } = props;

  return(
  <div className="row overview">
    <h3>Overview</h3> 
    <p>{desc}</p>
		{client &&
    	<h4>Client</h4>
		}
		<p className="bold">
			{clientLink &&
				<Link to={{ pathname: clientLink }} target="_blank">{client}</Link>
			}
			{!clientLink &&
				client
			}
		</p>
		{role &&
		<>
			<h4>Role</h4>
			<p className="bold">{role}
			{/* : {roleName} */}
			</p>
		</>
		}
		{teamName && 
			<>
			<h4>Team</h4>
			<p>{teamName}</p>
		</>
		}
    
  </div>
  )
}

export default Overview;


