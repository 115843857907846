// Amazon JP
import HeroImg8 from '../assets/images/Hero8.jpg';
import AmazonJPFinal1 from '../assets/images/AmazonJPGraphics1.jpg';
import AmazonJPFinal2 from '../assets/images/AmazonJPGraphics2.jpg';
import AmazonJPFinal3 from '../assets/images/AmazonJPGraphics3.jpg';
import AmazonJPFinal4 from '../assets/images/AmazonJPGraphics4.jpg';
import AmazonJPFinal5 from '../assets/images/AmazonJPGraphics5.jpg';
import AmazonJPFinal6 from '../assets/images/AmazonJPGraphics6.jpg';
import AmazonJPFinal7 from '../assets/images/AmazonJPGraphics7.jpg';
import Banner8 from '../assets/images/AmazonFooter.jpg';

// Amazon
import HeroImg9 from '../assets/images/Hero9.jpg';
import AmazonFinal1 from '../assets/images/AmazonGraphics1.jpg';
import AmazonFinal2 from '../assets/images/AmazonGraphics2.jpg';
import AmazonFinal3 from '../assets/images/AmazonGraphics3.jpg';
import AmazonFinal4 from '../assets/images/AmazonGraphics4.jpg';
import AmazonFinal5 from '../assets/images/AmazonGraphics5.jpg';
import AmazonFinal6 from '../assets/images/AmazonGraphics6.jpg';
import AmazonFinal7 from '../assets/images/AmazonGraphics7.jpg';
import Banner9 from '../assets/images/AmazonJPFooter.jpg';


const GalleryData = [
  {   
    heroData: {
      bgcolor: "#EBEAEB",
      imgPath: HeroImg8,
      imgAlt:"Amazon Graphics"  
    },
    detailData: [
      {
        title:"Final Deliverables", 
        imgPath: AmazonFinal1,
        imgAlt: "Amazon Graphics",
      },
      {
        imgPath: AmazonFinal2,
        imgAlt: "Amazon Graphics",
      },
      {
        imgPath: AmazonFinal3,
        imgAlt: "Amazon Graphics",
      },
      {
        imgPath: AmazonFinal4,
        imgAlt: "Amazon Graphics",
      },
      {
        imgPath: AmazonFinal5,
        imgAlt: "Amazon Graphics",
      },
      {
        imgPath: AmazonFinal6,
        imgAlt: "Amazon Graphics",
      },{
        imgPath: AmazonFinal7,
        imgAlt: "Amazon Graphics",
      },
    ],
    bannerData: {
      imgPath: Banner8,
      imgAlt:"Amazon Japan Graphics",
    },
  },
  {   
    heroData: {
      bgcolor: "#EBEAEB",
      imgPath: HeroImg9,
      imgAlt:"Amazon Graphics"
      
    },
    detailData: [
      {
        title:"Final Deliverables", 
        imgPath: AmazonJPFinal1,
        imgAlt: "Amazon Japan Graphics",
      },
      {
        imgPath: AmazonJPFinal2,
        imgAlt: "Amazon Japan Graphics",
      },
      {
        imgPath: AmazonJPFinal3,
        imgAlt: "Amazon Japan Graphics",
      },
      {
        imgPath: AmazonJPFinal4,
        imgAlt: "Amazon Japan Graphics",
      },
      { 
        imgPath: AmazonJPFinal5,
        imgAlt: "Amazon Japan Graphics",
      },
      {
        imgPath: AmazonJPFinal6,
        imgAlt: "Amazon Japan Graphics",
      },{
        imgPath: AmazonJPFinal7,
        imgAlt: "Amazon Japan Graphics",
      },
    ],
    bannerData: {
      imgPath: Banner9,
      imgAlt:"Amazon Graphics",
    },
  },
];

export default GalleryData;