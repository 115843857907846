// mamaPay
import HeroImg1 from '../assets/images/Hero1.jpg';
import detailImg1a from '../assets/images/MamaPay_ux_flow.png';
import detailImg1b from '../assets/images/MamaPayStyleGuide.jpg';
import imglink1b from '../assets/images/MamaPayStyleGuide.pdf';
import MamaPay1 from '../assets/images/MamaPay1.jpg';
import MamaPay2 from '../assets/images/mamaPay2.jpg';
import MamaPay3 from '../assets/images/MamaPayGraphics.jpg';
import Banner1 from '../assets/images/MamaPayFooter.jpg';
//ImgSlides
import MamaPaySlide0 from '../assets/images/mamaPayProblem1.jpg';
import MamaPaySlide1 from '../assets/images/MamaPayProblem2.jpg';

//TaguJam
import HeroImg2 from '../assets/images/Hero2.jpg';
import detailImg2a from '../assets/images/TagujamPersona.jpg';
import detailImg2b from '../assets/images/TagujamDesignSpec.jpg';
import detailImg2c from '../assets/images/TagujamStyleGuide.jpg';
import imglink2a from '../assets/images/TagujamPersona.pdf';
import imglink2b from '../assets/images/TagujamDesignSpec.pdf';
import imglink2c from '../assets/images/TagujamStyleGuide.pdf';
import Tagujam1 from '../assets/images/Tagujam1.jpg';
import Tagujam2 from '../assets/images/Tagujam2.jpg';
import Tagujam3 from '../assets/images/tagujam3.jpg';
import Tagujam4 from '../assets/images/tagujam4.jpg';
import Banner2 from '../assets/images/TagujamFooter.jpg';
//ImgSlides
import TagujamSlide0 from '../assets/images/TagujamProblem1.jpg';
import TagujamSlide1 from '../assets/images/TagujamProblem2.jpg';
import TagujamSlide2 from '../assets/images/TagujamProblem3.jpg';

//Style Guide
import HeroImg0 from '../assets/images/Hero0.gif';
import detailImg3a from '../assets/images/DsgProblems.jpg';
import detailImg3c from '../assets/images/DsgColorTheme.jpg';
import dsg1 from '../assets/images/Dsg1.jpg';
import dsg2 from '../assets/images/Dsg2.jpg';
import Bannerdsg from '../assets/images/DsgFooter.jpg';

// Samsung1
import HeroSamsungImg1 from '../assets/images/HeroSamsung1.jpg';
import detailSamsungWatch1 from '../assets/images/SamsungWatch1.jpg';
import detailSamsungWatch2 from '../assets/images/SamsungWatch2.png';
import BannerSamsung from '../assets/images/SamsungFooter2.jpg';
// Samsung2
import HeroSamsungImg2 from '../assets/images/HeroSamsung2.jpg';
import detailSamsungSearch1 from '../assets/images/SamsungSearch1.jpg';
import detailSamsungSearch2 from '../assets/images/SamsungSearch2.jpg';
// import BannerSamsung from '../assets/images/SamsungFooter2.jpg';
// Samsung3
import HeroSamsungImg3 from '../assets/images/HeroSamsung3.jpg';
import detailSamsungXsell1 from '../assets/images/SamsungXsell1.jpg';
import detailSamsungXsell2 from '../assets/images/SamsungXsell2.png';
// import BannerSamsung from '../assets/images/SamsungFooter2.jpg';
// Conca1
import HeroConcaImg from '../assets/images/HeroConca.jpg';
import detailConcaPersonaA from '../assets/images/ConcaDiscouragedDater.png';
import detailConcaPersonaB from '../assets/images/ConcaSelectiveSuitor.png';
import detailConcaAlgorithm from '../assets/images/ConcaAlgorithm.png';
import detailConcaSwimlane from '../assets/images/ConcaSwimlane.png';
import detailConcaSwimlaneDate from '../assets/images/ConcaSwimlaneDate.png';
import detailConcaWireframe from '../assets/images/ConcaGoal.jpg';
import BannerConca from '../assets/images/ConcaFooter.png';
// Conca Design System
import HeroConcaImgDS from '../assets/images/HeroConcaDS.jpg';
import detailConcaDS from '../assets/images/ConcaDS.jpg';
import detailConcaDSToken from '../assets/images/ConcaDSToken.png';
import detailConcaDSComponent from '../assets/images/ConcaDSComponent.png';
import detailConcaDSIllust from '../assets/images/ConcaDSIllust.png';
import BannerConcaDS from '../assets/images/ConcaDSFooter.png';
// Speed Dating Data
import HeroSPDImg from "../assets/images/HeroSPD.jpg"
import detailSP_PopularPeriod from "../assets/images/SPDPopularPeriod.png"
import detailSP_Order from "../assets/images/SPDOrder.png"
import detailSP_Shift from "../assets/images/SPDShift.png"
import BannerSP from "../assets/images/FooterSPD.png"

const UXData = [
	{   
		heroData: {
			imgPath: HeroConcaImgDS,
			imgAlt:"Conca Design System"
			
		},
		overviewData: {
			desc:"Conca required a straightforward and efficient design system to serve as a single source of truth. This system employs Design Tokens and Atomic Design principles to standardize elements such as colors, spacing, and other style guidelines.",
			client:"Conca",
			role: "UX/UI Designer: Kazue Otsuki, Team: Conca"
		},
		detailData: [
			{
				title:"Project & Goals", 
				imgPath: detailConcaDS,
				desc:"Conca required a straightforward and efficient design system to serve as a single source of truth. This system employs Design Tokens principles to standardize elements such as colors, spacing, and other style guidelines, facilitating consistency across teams and improving collaboration in design processes.",
				subTitle:"Single source of truth",
				urllink: "https://www.figma.com/design/D18KwDh2mwQAnnFkdb002y/Conca-Design-System?node-id=2004-8648&m=dev&t=QQR89XZjtnhQ0AH1-1",
				linkText: " View File",
			},
			{
				title:"Design Token", 
				imgLinkTitle: "Design Token",
				imgPath: detailConcaDSToken,
				imgAlt:"Design Token",
				desc:"The system includes two collections: Primitive and Brand. The Primitive collection defines fundamental properties such as color, border radius, and font size. The Brand collection incorporates these primitive values into specific element names, ensuring cohesive branding. For example, by integrating a wide range of colors into Primitive, a design system with enhanced customization options has been developed. Adding brand colors as a palette within the Brand collection makes it easy to adjust the color of any element as needed."
			},
			{
				title:"Components", 
				imgLinkTitle: "Components",
				imgPath: detailConcaDSComponent,
				imgAlt:"Components",
				desc:"The design system features two categories of components: base components and brand components. Base components are simple HTML elements that serve as the building blocks. Brand components are crafted by combining these base components, creating more complex and branded UI elements. Designers and engineers can easily create diverse components by mixing and matching these foundational elements."
			},
			{
				title:"Conca Onboarding Illustration", 
				imgLinkTitle: "Conca Onboarding Illustration",
				imgPath: detailConcaDSIllust,
				imgAlt:"Conca Onboarding Illustration",
				desc:"To quickly convey the concept, the onboarding illustrations were created using a combination of Adobe Illustrator's AI features. Some tweaking was necessary to maintain the desired illustration style while effectively communicating the concept."
			}
		],
		bannerData: {
			imgPath: BannerConcaDS,
			imgAlt:"Responsive Img",
		},
	},
	{   
		heroData: {
			imgPath: HeroConcaImg,
			imgAlt:"Conca"
			
		},
		overviewData: {
			desc:"Conca is a dating app where users looking for serious relationships can search for themselves. By using a matchmaker, the app matches users' preferences in more detail and promotes high-quality matches. By introducing only three people per week, it eliminates unnecessary searches and automatically sets up online dates based on the user's information immediately after matching, so users can meet quickly. This allows users to obtain information that cannot be provided through chat or email exchanges, making it possible to build high-quality relationships.",
			client:"Conca",
			role: "UX/UI Designer: Kazue Otsuki, Team: Conca team"
		},
		detailData: [
			{
				title:"Project & Goals", 
				imgPath: detailConcaWireframe,
				imgAlt:"Matchmaking app: Conca",
				// subTitle:"Discover genuine connections tailored to a busy life",
				desc:"Users are frustrated that they are unable to find a partner even though they have used marriage agencies, speed dates, and matching apps. Users often have higher expectations for their ideal partner than they think, and may be far from reality. Therefore, the aim of the app is to increase encounters effectively with high-quality partners by having matchmakers fine-tune the user's conditions in detail.",
			},
			{
				desc:"Based on insights gained from competitive analysis and by tracking specific hashtags and topics on social media and blogs, we developed a dating app that enables users to efficiently meet compatible partners. During this process, we discovered that users often had difficulty coordinating their schedules, so the team thoroughly brainstormed the necessary features. Using personas, we considered what kind of information architecture would help users navigate the app effectively. We repeatedly analyzed the process of how characters would smoothly go on a date using swimlane flowcharts, ultimately completing a product with minimum functionality.",
			},
			{
				title:"Persona", 
				imgLinkTitle: "The Discouraged Dater",
				imgPath: detailConcaPersonaA,
				imgAlt:"The Discouraged Dater",
				desc:"The Discouraged Dater:This person is having trouble finding a partner and is frustrated by the lack of results from online dating and paid subscriptions."
			},
			{
				imgLinkTitle: "The Selective Suitor",
				imgPath: detailConcaPersonaB,
				imgAlt:"The Selective Suitor",
				desc:"The Selective Suitor:This individual has high standards and seeks the perfect partner. It is necessary to align ideal conditions with realistic ones. The app is that uses a matchmaker to realistically assess user requirements and act as an intermediary between people seeking true connections."
			},
			{
				title:"Algorism", 
				imgLinkTitle: "Algorism",
				imgPath: detailConcaAlgorithm,
				imgAlt:"Algorism",
				desc:"In deciding on Conca's matching algorithm, our focus was on high-quality encounters rather than high matching rates, as determined through discussions based on personas."
			},
			{
				imgLink: detailConcaSwimlaneDate,
				imgLinkTitle: "Swimlane Flowchart",
				title:"Swimlane Flowchart", 
				imgAlt:"Swimlane Flowchart",
				imgPath: detailConcaSwimlane,
				desc:"There are four lanes in the process: participant, system, matchmaker and dating partner. To clearly define the interactions and states for each role, we created a swimlane flowchart. After several rounds of discussions, the chart was optimized to achieve the minimum viable product."
			}
		],
		bannerData: {
			imgPath: BannerConca,
			imgAlt:"Responsive Img",
		},
	},
	{   
		heroData: {
			imgPath: HeroSPDImg,
			imgAlt:"Speed Dating Analysis"
			
		},
		overviewData: {
			desc:"Data from the speed-dating events was compiled by Columbia Business School professors Ray Fisman and Sheena Iyengar. This publicly available data was used to explore participants' expectations and self-perceptions, gender differences in matching decisions, and the circumstances or attributes that influenced those decisions.",
			role: "Data Visualization Designer"
		},
		detailData: [
			{
				title:"Project & Goals", 
				// embedTableau: "https://public.tableau.com/views/SpeedDating_17235905371620/SpeedDatingData?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link",
				subTitle:"Visualize the data with Tableau",
				desc:"Based on this public dataset, changes in participants' perceptions before and after the event were visualized in Tableau. In the analysis, we examined changes in partner and self-evaluation using attribute evaluation questionnaires completed at registration, during the event, and after the event. (Attributes: attractive, sincere, intelligent, fun, ambitious, common interests)",
				urllink: "https://public.tableau.com/views/SpeedDating_17235905371620/SpeedDatingData?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link",
				linkText: " View Tableau File",
			},
			{
				title:"Popular Period", 
				imgLinkTitle: "Popular Period",
				imgPath: detailSP_PopularPeriod,
				imgAlt:"Popular Period",
				desc:"The average number of self-estimated matches is not that different for each age group, but when we look at the actual number of matches, we see that people in their 20s and 30s have more matches. Looking at the results by gender, women tend to be more popular 1-2 years later than men."
			},
			{
				title:"Is the First Person to Speak Impactful?", 
				imgLinkTitle: "Is the First Person to Speak Impactful?",
				imgPath: detailSP_Order,
				imgAlt:"Is the First Person to Speak Impactful?",
				desc:"Participants were split into two groups, called \"waves,\" and spoke for approximately 4 minutes before moving on to the next person. The number of matches was determined by the order in which participants spoke. In our analysis of the dataset, many people matched with the person who spoke first, but this pattern was not consistent across all wave groups."
			},
			{
				title:"Shifts in Participants' Feelings", 
				imgLinkTitle: "Shifts in Participants' Feelings",
				imgPath: detailSP_Shift,
				imgAlt:"Shifts in Participants' Feelings",
				desc:"The attribute evaluation questionnaire completed by the participants shows how scores changed from the time of sign-up to after the event. A negative value indicates that scores for the attributes were higher at sign-up, while a positive value indicates that scores were higher after the event.",
			},
			{
				subTitle:"Shift in Self-evaluation by Others", 
				desc:"Although there was less overconfidence and self-evaluation overestimation than expected, participants reassessed and lowered their own evaluations during and after the event due to comparisons and interactions with others.",
			},
			{
				subTitle:"Shift in Preferred Attributes in a Partner", 
				desc:"Regardless of gender, attractiveness was considered important. Participants lowered their assessment of intelligence after the event because they might have come to value culture, social background, and values more, adjusting their criteria accordingly.",
			},
			{
				subTitle:"Shift in Ideal Attributes Desired by the Opposite Sex (Based on Self-Predictions)", 
				desc:"The event significantly impacted participants' perceptions of what others value and expect. Male participants, in particular, came to believe more strongly that attractiveness is important to women. In contrast, female participants came to see common interests as more important.",
			},
			{
				subTitle:"Shift in Attributes That Same-Sex People Look for in a Partner (Based on Self-Predictions)", 
				desc:"The event revealed a gap between how men and women perceive the same sex. Male participants felt that men tend to place more importance on attractiveness, while female participants believed that women value different attributes more, indicating differing values and priorities.",
			}
		],
		bannerData: {
			imgPath: BannerSP,
			imgAlt:"Responsive Img",
		},
	},
	{   
    heroData: {
      imgPath: HeroSamsungImg1,
      imgAlt:"Samsung"
      
    },
    overviewData: {
      desc:"The Galaxy Watch5 Model Inventory Management Initiative optimized inventory practices for the Galaxy Watch5 by strategically integrating visually coordinated product shots featuring older SKUs. The initiative aimed to enhance the customer selection process by presenting a cohesive product range, empowering customers to make informed decisions.",
      client:"Samsung Electronics America",
			role: "UX/UI Designer: Kazue Otsuki, PM: Aaron Wang, Team: NCMC Team (New Computing & Mobile Experience)"
    },
    detailData: [
      {
        title:"Project & Goals", 
        imgPath: detailSamsungWatch1,
        imgAlt:"Inventory management and efficient customer selection process",
        subTitle:"Inventory management and efficient customer selection process",
        desc:"The core tactic involved leveraging visually appealing thumbnails to showcase older SKUs, strategically positioned above product configuration items to highlight the aging inventory. Upon selecting the thumbnail, customers could proceed to check out immediately. This approach played a crucial role in successfully selling out older inventories, aligning with the project's overarching goals.",
      },
      {
        title:"Enhancing User Visibility", 
        imgLinkTitle: "Enhancing User Visibility",
        imgPath: detailSamsungWatch2,
        imgAlt:"Enhancing User Visibility",
        // subTitle:"Enhancing User Visibility",
        desc:"Given the limited view on Samsung's mobile interface, a horizontal scroll is implemented to display coordinated product thumbnails. This ensures that the product configuration selection remains visible."
      }     
    ],
    bannerData: {
      imgPath: BannerSamsung,
      imgAlt:"Responsive Img",
    },
  },
	{   
    heroData: {
      imgPath: HeroSamsungImg2,
      imgAlt:"Samsung - Search"
      
    },
    overviewData: {
      desc:"A strategic search overlay was implemented in the global navigation to enhance the visibility of new flagship devices and streamline the search process for customers. The goal was to improve the user experience by providing a swift and convenient method for users to access information without interrupting their ongoing browsing or interaction.",
      client:"Samsung Electronics America",
			role: "UX/UI Designer: Kazue Otsuki, PM: Lana Le"
    },
    detailData: [
      {
        title:"Project & Goals", 
        imgPath: detailSamsungSearch1,
        imgAlt:"A Strategic Search Overlay for Enhanced Visibility Flagship Devices",
        subTitle:"A Strategic Overlay for Enhanced Visibility of Flagship Devices",
        desc:"The redesign of the search modal was discussed with other UX designers and teams to coordinate efforts, utilizing Sketch and InVision tools and providing various solutions. Through competitive analysis, the latest model images were presented in an easy-to-understand manner for users, and a scrollable featured image was added for quick access to effective flagship products.",
      },
      {
        title:"Seamless Navigation of Flagship Devices", 
        imgLinkTitle: "Flagship images",
        imgPath: detailSamsungSearch2,
        imgAlt:"Optimizing User Experience",
        subTitle:"Optimizing User Experience",
        desc:"The implemented enhancements to the search overlay model resulted in a remarkable 13% increase in Conversion Rate (CVR)"
      }    
    ],
    bannerData: {
      imgPath: BannerSamsung,
      imgAlt:"Responsive Img",
    },
  },
	{   
    heroData: {
      imgPath: HeroSamsungImg3,
      imgAlt:"A Strategic Cross-sell screen improvement"
      
    },
    overviewData: {
      desc:"Due to a high bounce rate reported on the cross-cell screen, the design needed to be reorganized. Feedback from the Net Promoter Score (NPS) indicated issues with the design including the lack of relevant product displays and challenges in identifying newly added products. Moreover, customers sometimes passed by without paying attention to the screen. Improving the cross-sell screen to display relevant content was deemed essential.",
      client:"Samsung Electronics America",
			role: "UX/UI Designer: Kazue Otsuki, PM: Aaron Wang, Teams: Mobile Experience, New Computing & Mobile Experience, and Accesories"
    },
    detailData: [
      {
        title:"Project & Goals", 
        imgPath: detailSamsungXsell1,
        imgAlt:"Cross-sell screen improvement",
        subTitle:"A Strategic Cross-sell screen improvement",
        desc:"To tackle this challenge, collaborative efforts were initiated with cross-functional teams. The focus was on streamlining the category tab numbers, reducing them from 6 to 3, and imposing a limit of 12 recommended products. Utilizing insights from the teams and customer feedback via NPS, redesigned the layout of the cross-sell screen to display single items in a card, ensuring at-a-glance visibility. Engagement rate improved by 20%.",
      },
      {
        imgPath: detailSamsungXsell2,
        imgAlt:"Optimizing User Experience",
      }    
    ],
    bannerData: {
      imgPath: BannerSamsung,
      imgAlt:"Responsive Img",
    },
  },
  {   
    heroData: {
      imgPath: HeroImg0,
      imgAlt:"Dynamic Style Guide"
      
    },
    overviewData: {
      desc:"To improve the efficiency of projects on apps and websites, we need a style guide that anyone can understand at a glance. Immediately reflect changes in components, color themes, dark / light modes, etc. in the library for efficient development. Easy library maintenance with React, Dynamic CSS and SASS.",
      client:"TaguJam",
      role:"UI/UX Designer"
    },
    detailData: [
      {
        title:"Project & Goals", 
        imgPath: detailImg3a,
        imgAlt:"React Component",
        subTitle:"Dynamic Design Specification",
        desc:"The goal is to build a design library that everyone can understand at a glance. Semantic naming conventions make them easier to refer to components and color themes. The library should improve design consistency and accurately communicate with team members. It eliminates the need for developers to review the details.",
				urllink: "https://style-library-e1b5f.web.app",
				linkText: " View",
      },
      {
        title:"React Components", 
        imgLinkTitle: "Color Theme",
        imgPath: dsg1,
        imgAlt:"Reuseable",
        subTitle:"Reuse Anywhere",
        desc:"React Components are reusable, and maintenance is easy. During the development of this library, it enables to respond quickly to any changes. They were updated instantly without reloading."
      },
      {
        imgLinkTitle: "Dark/Light Mode & Color Theme",
        imgPath: detailImg3c,
        imgAlt:"Dark/Light Mode & Color Theme",
        subTitle:"Creating Color palette with SASS and React",
        desc:"Simplify management of color themes and light/dark modes; create color palettes using SASS maps and the \"hsl\" color function; consolidate color variables in one place using \"mixin\" which instantly switch colors for each element using Dynamic CSS and React Context. This will allow you to create a six-color theme for efficiencies."
      },
      {
        imgPath: dsg2,
        imgAlt: "Dynamic Style Guide: Responsive"
      }      
    ],
    bannerData: {
      imgPath: Bannerdsg,
      imgAlt:"Responsive Img",
    },
  },
  {   
    heroData: {
      imgPath: HeroImg2,
      imgAlt:"TaguJam"
      
    },
    overviewData: {
      desc:"TaguJam is a mobile market application. It's for the market where anyone can easily buy and sell services with a price tag. The purpose of this website is to make TaguJam a glimpse of how the app can help a wide variety of busy people. On the home page, it was necessary to emphasize this point and have hero Image specifically represent each main user. Furthermore, in order to let people know more about the community platform that connects people who want to offer what they can and what they want to buy, the website appeals to you that you can use it safely with user guide and flow.",
      client:"TaguJam",
			clientLink: "https://tagujam.com/",
      role:"UX/UI Designer: Kazue Otsuki, Team TaguJam"
    },
    detailData: [
      {
        title:"Project & Goals", 
        slidesData: [TagujamSlide0, TagujamSlide1, TagujamSlide2],
        subTitle:"TaguJam Goals",
        desc:"TaguJam needs to be competible with small screen size such as iPhone SE. It was essential to design to fit on the small screen. All hero images' messages are carefully crafted for legibility as well as the hero content. Responsiveness allows users to keep focusing on information about TaguJam apps by reading information in the order. Also, the site was enhanced more user friendly as all icons are displaying with text.",
      },
      {
        title:"Project Process", 
        imgLink: imglink2a,
        imgLinkTitle: "Persona",
        imgPath: detailImg2a,
        imgAlt:"Persona",
        subTitle:"Persona",
        desc:"TaguJam users want a place where sellers can contribute to society on a daily basis. However, these users often don't know where to start because they don't know how much contribution they want. As a result, this precious resource is buried. Also, many mothers and households in need of long-term care need a little help because the lack of public long-term care services puts a heavy burden on a daily basis. TaguJam is the best bridge to connect your needs.",
      },
      {
        imgLink: imglink2b,
        imgLinkTitle: "TaguJam Spec Redline",
        imgPath: detailImg2b,
        imgAlt:"TaguJam Spec Redline",
        subTitle:"TaguJam Spec Redline",
        desc:"To represent TaguJam at-glance, there are six hero images, and This specific image is constructed with two layers. Hand holding the iPhone is located specific area, yet not to overlap. It requires to set specific pixels as the engineer to write the responsive code. Also, the download buttons need to be under the hero image area for the messaging legibility. Since TaguJam is a new application, it needs more sellers. In order to treat, seller and buyer information equally, the information is in tabs.",
      },
      { 
        imgLink: imglink2c,
        imgLinkTitle: "TaguJam Style Guide",
        imgPath:detailImg2c,
        imgAlt:"TaguJam Style Guide",
        subTitle:"TaguJam Style Guide",
        desc:"There was a requirement so that TaguJam users could intuitively understand the four categories. By color coding with theme colors, we made it clear where the user was. Also, by adding text to the icon according to the target user, the user can seamlessly use the TaguJam system.",
      },
      {
        imgPath: Tagujam1,
        imgAlt: "TaguJam Final Responsive Site",
      },
      {
        imgPath: Tagujam2,
        imgAlt: "TaguJam Final Responsive Site",
      },
      {
        imgPath: Tagujam3,
        imgAlt: "TaguJam Hero Graphics",
      },
      {
        imgPath: Tagujam4,
        imgAlt: "TaguJam Site Graphics",
      },
    ],
    bannerData: {
      imgPath: Banner2,
      imgAlt:"Responsive Img",
    },
  },
	{   
    heroData: {
      imgPath: HeroImg1,
      imgAlt:"mamaPay"
    },
    overviewData: {
      desc:"mamaPay is a payment simulator assists parents. Every time child complete a task, s/he can get mamaPay points. The app supports parents whose children stay at home during school closure caused such as Corona Virus (COVID-19). It's hard to work from home for parents with younger children from 5~12 years old. At home, it's hard to discipline children to do their daily routines. By using mamaPay app keeps the children well behaved, and even motivate help their parents. mamaPay plays a huge role in their routine life.",
      client:"TaguJam",
      role:"UX Designer"
    },
    detailData: [
      {
        title:"Project & Goals", 
        slidesData: [MamaPaySlide0, MamaPaySlide1],
        subTitle:"Help Mom",
        desc:"An app that lets children experience a successful experience of completing tasks one by one. Children will be rewarded with the collected mom pay while having fun and communicating with mom. By fulfilling the goals, we made it possible for children to perform tasks that they were given happily and voluntarily. Since the market for this app is Japan, we express cuteness with a friendly character style. The children's screen was as colorful as possible, and the parent's screen softened the colors.",
      },
      {
        title:"Project Process", 
        imgLinkTitle: "mamaPay UX Flow",
        imgPath: detailImg1a,
        imgAlt:"mamaPay UX Flow",
        subTitle:"mamaPay UX Flow",
        desc:"On the home screen of mamaPay, at a glance which approval was requested by the child. If you have more than one child, select the child in the top nub and the details will appear on the screen.",
      },
      { 
        imgLink: imglink1b,
        imgLinkTitle: "Style Guide 2",
        imgPath: detailImg1b,
        imgAlt:"mamaPay Image",
      },
      {
        imgPath: MamaPay1,
        imgAlt: "mamaPay Final Mom",
      },
      {
        imgPath: MamaPay2,
        imgAlt: "mamaPay Final Kid",
      },
      {
        imgPath: MamaPay3,
        imgAlt: "mamaPay Graphics",
      },
    ],
    bannerData: {
      imgPath: Banner1,
      imgAlt:"mamaPay",
    },
  }
];

export default UXData;