import React from 'react';
import { Redirect } from 'react-router-dom';
import './Graphics.scss';
import Hero from '../../components/Hero/Hero';
import Overview from '../../components/Overview/Overview';
import Detail from '../../components/Detail/Detail';
import Banner from '../../components/Banner/Banner';
import GraphicsData from '../../constants/GraphicsData';


const Graphics = (props) => {
  if (!props.match.params.pageID) {
    return (
      <Redirect to="/graphics/1" />
    );
  }
  else {
    console.log(props);
    const pageID = props.match.params.pageID; 
    const pageData = GraphicsData[pageID];

    return(
      <div className="main"> 
        <Hero  
          bgColor={pageData.heroData.bgColor}
          imgPath={pageData.heroData.imgPath} 
          imgAlt={pageData.heroData.imgAlt} 
        />
        <Overview 
          desc={pageData.overviewData.desc}
          client={pageData.overviewData.client}
					clientLink={pageData.overviewData.clientLink}
          role={pageData.overviewData.role}
          roleName={pageData.overviewData.roleName}
					teamName={pageData.overviewData.teamName}
        />
        {pageData.detailData.map((ddata, index) => {
          const {title, imgLink, imgLinkTitle, imgPath, imgAlt, subTitle, desc} = ddata;
          return(
            <Detail 
              key={`${title}_${index}`}
              title={title}
              imgLink={imgLink}
              imgLinkTitle={imgLinkTitle}
              imgPath={imgPath}
              imgAlt={imgAlt}
              subTitle={subTitle}
              desc={desc}
            />
          )
          })
        }
        <Banner 
          imgPath={pageData.bannerData.imgPath}
          imgAlt={pageData.bannerData.imgAlt} 
        />
      </div>
    )
  }
}

export default Graphics;