import React, { useState } from 'react';
import './Detail.scss';
import ImageSlides from '../ImageSlides/ImageSlides';
import { Link } from 'react-router-dom';

const Detail = (props) => {

  const {
    imgPath,					// if slidesData, optional
    imgLink,					// if imgLink, optional
    imgAlt,						// required
    title,						// if imgLink
    imgLinkTitle,			// if imgLink, optional
    slidesData,				// if imagePath, optional
    desc,							
    subTitle,					// optional
		urllink,					// optional
		linkText,					// optional
		items						// optional
		// embedTableau
  } = props;

  let customWorkImage = { 
    margin: "0em auto 0em auto", 
  }; 

	// const textFormat = ({ text, boldText }) => {
	// 	const formattedText = text.split(boldText).map((part, index) => (
	// 		index === 0 ? part : [<b key={index}>{boldText}</b>, part]
	// 	));
	// }
  return(
    <div className="row detail"> 
      {title && 
      <h3>{title}</h3>
      }
      {/* {(imgPath && imgLink && subTitle) && */}
			{(imgPath && imgLink) &&
      <a 
        href={imgLink} 
        title={imgLinkTitle}
        target="_new">
        <img 
          className="workImage" 
          style={(title) ? customWorkImage : null}
          src={imgPath} 
          alt={imgAlt} 
          />
      </a>
      }
      {(imgPath && !imgLink && !subTitle) &&
      <img 
        className="workImage" 
        src={imgPath} 
        alt={imgAlt} 
      />
      }
      {(imgPath && !imgLink && subTitle) &&
      <img 
        className="workImage" 
        // style={(title) ? customWorkImage : null}
        src={imgPath} 
        alt={imgAlt} 
      />
      }
      {slidesData && 
      	<div style={{margin: "0 auto 0em auto"}}>
        <ImageSlides slidesData={slidesData} />
      </div>
      }
      <div className="projDesc">
        {subTitle && 
          <h4>{subTitle}</h4>
        }
        {desc &&
          <p>{desc}
					{(urllink) &&
						<span className="bold">
							<Link to={{ pathname: urllink }} target="_blank">{linkText}</Link>
						</span>
					}
					{/* {desc && 
						{textFormat}
					} */}
					</p>
        }
				
				{/* <tableau-viz 
					id="tableauViz" 
					className="embedTableau" 
					style={{height: "1500px", border: "5px pink"}}
					src="https://public.tableau.com/app/profile/kazue.otsuki/viz/SpeedDating_17235905371620/SpeedDatingData"
					toolbar="bottom" hide-tabs
				>
				</tableau-viz>  */}
      </div>
    </div>
  )
}

export default Detail;
