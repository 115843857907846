import React, { Component } from 'react';
import './ImageSlides.scss';


class ImageSlides extends Component {

  state = { 
    selectedIndex: 0 // init value: do not change directory
  };

  calcIndex = (num) => {
    let {selectedIndex} = this.state;
    const {slidesData} = this.props;
    let currentIndex = selectedIndex;
    
    currentIndex = currentIndex + num;
    if (currentIndex < 0) {
      currentIndex = slidesData.length - 1;
    }
    if (currentIndex > slidesData.length - 1) {
      currentIndex = 0;
    }
    this.updateIndex(currentIndex);
  }

  updateIndex = (num) => {
    this.setState(
      { 
        selectedIndex: num
      }
    )
  }

  render = () => {
    const {selectedIndex} = this.state;
    const {slidesData, navColor} = this.props;

    return(
      <div className="imageSlides">
        { slidesData.map((img, index) => {
            return(
              <img 
                key={`${index}_imageSlides`}
                className="slideImage fade"
                src={img} 
                alt={index}
                style={{ display: selectedIndex === index ? "block" : "none"}}
             />
            )
         })
        }
        <div className="dotContainer">
          { slidesData.map((img, index) => {
              return(
                <div 
                  key={`${index}_imageDot`}
                  className="dot"
                  style={{ 
                    opacity: selectedIndex === index ? 1 : 0.5,
                    background: navColor ? navColor : null
                  }}
                  onClick={() => {
                    this.updateIndex(index)
                  }}
                />
              )
          })
          }
        </div>
        <div className="navContainer">
          <div 
            style={{
              color: navColor ? navColor : null
            }}
            className="prev"
            onClick={() => {
              this.calcIndex(-1)
            }}>
             &#10094;
          </div>
          <div 
            style={{
              color: navColor ? navColor : null
            }}
            className="next"
            onClick={() => {
              this.calcIndex(1)
            }}>
             &#10095;
          </div>
        </div>
      </div>
    )
  }
}

// const ImageSlides = (props) => {
//   const ImageSlidesData = [Img0, Img1, Img2];

//   let selectedIndex = 1;
  
//   return(
//     <div className="imageSlides">
//       { ImageSlidesData.map((img, index) => {
//           return(
//             <img 
//               key={`${index}_imageSlides`}
//               src={img} 
//               alt={index}
//               style={{ display: selectedIndex === index ? "block" : "none"}}
//            />
//           )
//        })
//       }
//       <div className="dotContainer">
//         { ImageSlidesData.map((img, index) => {
//             return(
//               <div 
//                 key={`${index}_imageDot`}
//                 className="dot"
//                 style={{ opacity: selectedIndex === index ? 1 : 0.5}}
//               />
//             )
//         })
//         }
//       </div>
//       <div className="navContainer">
//         <div className="prev">&#10094;</div>
//         <div className="next">&#10095;</div>
//       </div>
//     </div>
//   )
// }

export default ImageSlides;
