import React from 'react';
import './AboutMe.scss';

const AboutMe = (props) => {
	// const {
	// 	resume
	// } = props;
	// console.log(resume);
  return(
    <div className="row about">
      <h3>Kazue Otsuki</h3>
      <p>I have worked in visual design fields. I have been working on various campaigns projects. I am fortunated to work vrious degital advertising projects with many Art Directors. Also, when working with UX designers, it was facinating tounderstand customer journey and created many landing page design testing. After working with various campaign teams, I wanted to more about User Experiences Design to enhance a site visually as well as experience. My current focus skills are user interface and user experience design. In my spare time, I enjoy birdwatching.</p>
      <ul className="list">
        <li>Experience with digital media planning, design and production for web development</li>
        <li>Thorough experience designing static and animated web interfaces, using CSS/HTML, video, and JavaScript</li>
        <li>Experience in digital asset organization and management</li>
        <li>Web Production experience in React</li>
      </ul> 
     <p>
			 <a href="/assets/media/kazue_otsuki_resume.pdf" target="_blank">View Resume</a>
		 </p>
    </div>
  )
}

export default AboutMe;