import React from 'react';
import Styles from './ThumbLayout.module.scss';
import Thumbnail from '../Thumbnail/Thumbnail';

const ThumbLayout = (props) => {
  return(
    <div className={Styles.container}>
      { props.thumbnails.map((thumb, index) => {
          return(
            <div className={Styles.col} key={thumb.title + index}>
              <Thumbnail 
                imgPath={thumb.imgPath} 
                imgAlt={thumb.imgAlt} 
                title={thumb.title}
                desc={thumb.desc}
                goTo={thumb.goTo} />
            </div>
          )
        })
      }
    </div>
  )
}

export default ThumbLayout;
