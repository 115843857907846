import React from 'react';
import './About.scss';
import Hero from '../../components/Hero/Hero';
import HeroImg from '../../assets/images/AboutHero.jpg';
import Banner from '../../components/Banner/Banner';
import banner from '../../assets/images/AboutFooter.jpg';
import AboutMe from '../../components/AboutMe/AboutMe';

const About = (props) => {
  return(
    <div>
      <Hero 
        bgColor="#EBEAEB"
        imgPath={HeroImg} 
        imgAlt="About Kazue" 
      />
      <AboutMe />
      {/* <Contact /> */}
      <Banner 
          imgPath={banner}
          imgAlt="Responsive Img"
      />
    </div>
  )
}

export default About; 