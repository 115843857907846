import React from 'react';
import './Footer.scss';

const Footer = (props) => {
  return(
    <div className="row">
      <div className="contact">
        <a href="mailto:info@kazueotsuki.com" title="Contact Kazue">Contact Kazue</a>
        <p className="copyright">&copy; Kazue Otsuki</p>
      </div>
  </div>
  )
}

export default Footer;




