// FireTV
import HeroImg4 from '../assets/images/Hero4.jpg';
import detailImg1a from '../assets/images/FireTVProblem.jpg';
import detailImg1b from '../assets/images/FireTVStoryboard.jpg';
import FireTV1 from '../assets/images/FireTV1.jpg';
import FireTV2 from '../assets/images/FireTV2.jpg';
import Banner4 from '../assets/images/FireTVFooter.jpg';

// Vday
import HeroImg5 from '../assets/images/Hero5.jpg';
import detailImg2a from '../assets/images/VdayProblem.jpg';
import Vday1 from '../assets/images/Vday1.jpg';
import Vday2 from '../assets/images/Vday2.jpg';
import Vday3 from '../assets/images/Vday3.jpg';
import Banner5 from '../assets/images/VdayFooter.jpg';

//Launchpad
import HeroImg6 from '../assets/images/Hero6.jpg';
import detailImg3a from '../assets/images/LaunchpadProblem.jpg';
import Launchpad1 from '../assets/images/Launchpad1.jpg';
import Launchpad2 from '../assets/images/Launchpad2.jpg';
import Launchpad3 from '../assets/images/Launchpad3.jpg';
import Banner6 from '../assets/images/LaunchpadFooter.jpg';

//BTS
import HeroImg7 from '../assets/images/Hero7.jpg';
import detailImg4a from '../assets/images/BTSProblem.jpg';
import BTS1 from '../assets/images/BTS1.jpg';
import BTS2 from '../assets/images/BTS2.jpg';
import BTS3 from '../assets/images/BTS3.jpg';
import Banner7 from '../assets/images/BTSFooter.jpg';
const GraphicsData = [
  {   
    heroData: {
      bgcolor: "#EBEAEB",
      imgPath: HeroImg4,
      imgAlt:"FireTV"
      
    },
    overviewData: {
      desc:"FireTV family has been a very popular product. In this campaign, the focus is that with the Alexa Voice Remote, press and ask to easily find any your favorite movies and TV episodes, plus live news and sports for common people. The digital ad project required to illustrate how the device works. Working closely with Art Directors, copy writer, my team contributed to create HTML5 animation ads to demonstrate how Alex can work with FireTV",
      client:"Amazon FireTV",
      role:"Visual Designer",
      teamName:"Art Director: Colin Gaul, Visual Designer: Kazue Otsuki and Cely Henriquez, Producer: Tika Meloy"
    },
    detailData: [
      {
        title:"Project & Goals", 
        // imgLink: "http://www.google.com",
        imgLinkTitle: "Hands Free Devices",
        imgPath: detailImg1a,
        imgAlt:"FireTV: Hands Free Devices",
        subTitle:"Hands Free Devices",
        desc:"FireTV was accepted by new enthusiasts, but I needed to make it clear that it would work with ”Alexa”. In particular, the purpose of the Fire TV Cube was to get people to understand that they can control an ”Alexa” compatible TV with only their voice. Therefore, in HTML5 Ad banner, it required the function by including Alexa's speech bubble and TV interaction.",
      },
      {
        title:"Project Process", 
        imgPath: detailImg1b,
        imgAlt:"FireTV: Hands Free Devices",
        subTitle:"Hands Free Devices",
        desc:"It's important to illustrate how users can command Alexa to do everyday life. This frames to display steps of \"Light Dim\". It was animated by Adobe Animate.",
      },
      {
        title:"Final Deliverables", 
        imgPath: FireTV1,
        imgAlt: "MamaPay Final Mom",
      },
      {
        imgPath: FireTV2,
        imgAlt: "MamaPay Final Kid",
      },
    ],
    bannerData: {
      imgPath: Banner4,
      imgAlt:"Hands Free Device",
    },
  },
  {   
    heroData: {
      bgcolor: "#EBEAEB",
      imgPath: HeroImg5,
      imgAlt:"Valentine's Day"
      
    },
    overviewData: {
      desc:"Valentine's Day is not just for romantic couples. In this campaign, Amazon's Valentine's Day storefront will allow customers to shop by recipient in gift collection pages. Each collection themes were uniquely designed and curated products for the customers. The effort is to provide customers with inspirational cross-site, themes, and a curated customer experience to enhance engagement and conversions.",
      client:"Amazon Events",
      teamName:"Art Director: Sarah Sinclair, Marketing Specialist: Lynn Caiazza, Project Manager: Jenna Rerucha, Event Manager: Joseph Mejia, Visual Designer: Kazue Otsuki, Graphic Designer: Kawika Sweeney",
      // roleName:"Kazue Otsuki",
    },
    detailData: [
      {
        title: "Project & Goals", 
        // imgLink: "http://www.google.com",
        // imgLinkTitle: "Valentine's Day",
        imgPath: detailImg2a,
        imgAlt:"Valentine's Day Goals",
        subTitle:"More and more images and contents …",
        desc:"Valentine's day is another bigger sales after the holiday. According to National Retail Federation, Every year the Valentine's spending goes to increased significantly. Adding Specific customers to allow shop at Amazon by creating themes, customers can enjoy shopping unique gifts for their loved ones. The banner real estate is limited. An A/B test indicated in different campaigns that simple images always perform better. Amazon had been able to reach out customers with specific tastes for this special event. ",
      },
      { 
        title:"Final Deliverables", 
        imgPath: Vday1,
        imgAlt:"Final Deliverables",
      },
      {
        imgPath:Vday2,
        imgAlt: "Valentine's Day Graphics in Devices"
      },
      {
        imgPath: Vday3,
        imgAlt: "Valentine's Day Graphics"
      },
    ],
    bannerData: {
      imgPath: Banner5,
      imgAlt:"Valentine's Day",
    },
  },
  {   
    heroData: {
      bgcolor: "#EBEAEB",
      imgPath: HeroImg6,
      imgAlt:"Launchpad"
      
    },
    overviewData: {
      desc:"Amazon Launchpad is a unique platform at Amazon as this is a market with a series of cutting edge innovative items produced by vetted startups. This digital advertisement is the Amazon Launchpad showcase of emerging products. In the Father's Day and Graduation event ads, Most challenging part was to portray unity of nature and cutting-edge technology.",
      client:"Launchpad",
      role: "Visual Designer",
      roleName:"Kazue Otsuki"
    },
    detailData: [
      {
        title:"Project & Goals", 
        imgLinkTitle: "Project &amp; Goals",
        imgPath: detailImg3a,
        imgAlt:"Launchpad",
        subTitle:"Innovative and futuristic Products",
        desc:"This Launchpad style guide provided a various range of nature background images and some graphic patterns to help easy to represent some products. Launchpad offers wide varieties of products, and at-glance, some products are hard to recognize what it is. It associates those products with the background images. For example, the mountain image has given perspective; therefore, it represents with the drone products. Even if customers don't know what a product is, if they can get their attention, they can imagine it.",
      },
      {
        title:"Final Deliverables", 
        imgPath: Launchpad1,
        imgAlt:"Launchpad Graphics",
      },
      {
        imgPath: Launchpad2,
        imgAlt: "Launchpad Graphics in Devices"
      },
      { 
        imgPath:Launchpad3,
        imgAlt:"Launchpad Graphics",
      },
    ],
    bannerData: {
      imgPath: Banner6,
      imgAlt:"Launchpad Graphics",
    },
  },
  {   
    heroData: {
      bgcolor: "#EBEAEB",
      imgPath: HeroImg7,
      imgAlt:"Back to School"
      
    },
    overviewData: {
      desc:"Cardboard symbolized Amazon retail, and it was used to create a landing page with a fusion of school goods. It was a concept that linked the joy of receiving products to the BTS campaign using the Amazon box as a material. ",
      client:"Amazon Eevents",
      role: "Associate Creative Director: Hope Jata, Project Manager: Jenna Rerucha, Visual Designer: Kazue Otsuki"
      // roleName:" "
    },
    detailData: [
      {
        title:"Project & Goals", 
        imgPath: detailImg4a,
        imgAlt:"pProject &amp; Goals",
        subTitle:"Cardboard Crafted Alphabets!",
        desc:"Crafted Cardboard alphabets (Amazon Box) were created for shooting, and those photo materials were provided to create the landing page. It is a challenge to maintain the look and feels of the cardboard. Also it should look playful the school supplies and goods, and the excitement of the school supply purchase experience for the customer. It is fun that the production is full of color from kid's point of view.",
      },
      {
        title:"Final Deliverables", 
        imgPath: BTS1,
        imgAlt: "Launchpad Graphics in Devices"
      },
      {
        imgPath: BTS2,
        imgAlt:"Launchpad Graphics",
      },
      {
        imgPath: BTS3,
        imgAlt: "Launchpad Graphics in Devices"
      },
    ],
    bannerData: {
      imgPath: Banner7,
      imgAlt:"Launchpad Graphics",
    },
  }
];

export default GraphicsData;