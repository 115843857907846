import React from 'react';
import './Hero.scss';

const Hero = (props) => {
  const {
    imgPath,
    imgAlt
  } = props;

    return(
      <div className="hero">
        <img className="heroImg" src={imgPath} alt={imgAlt} />
      </div>
    )
}

export default Hero;

