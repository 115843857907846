import React from 'react';
import './Banner.scss';

const Banner =(props) => {
  return(
    <div className="banner">
      <div className="bannerImg">
        <img className="bannerImg" src={props.imgPath} alt={props.imgAlt} />
      </div>
    </div>
  )
}

export default Banner;