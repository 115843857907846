import React from 'react';
import './App.scss';
import Home from './container/Home/Home';
import UX from './container/UX/UX';
import Graphics from './container/Graphics/Graphics';
import Gallery from './container/Gallery/Gallery';
import About from './container/About/About';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Route, Switch, Redirect } from 'react-router-dom';

function App() {
  return (
    <div className="App">

      <Header />
      <Switch>
        <Route path="/ux/:pageID" component={UX} />
        <Route path="/ux" component={UX} />
        <Route path="/graphics/:pageID" component={Graphics} />
        <Route path="/graphics" component={Graphics} />
        <Route path="/gallery/:pageID" component={Gallery} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/about" component={About} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
